import React, { useEffect } from 'react'
import { signoutRedirectCallback, signinRedirect } from 'utils/oidcClientExtension';
import { onPostUserLogoutMessage } from 'utils/broadcastChannelUtils';

function SignoutOidc() {
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback()
      onPostUserLogoutMessage();
      signinRedirect()
    }
    signoutAsync()
  }, [])

  return (<div></div>)
}

export default SignoutOidc
